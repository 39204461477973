@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');

body {
	font-family: 'Amatic SC', cursive;
	background-image: url('../src/images/envelhecido.jpg');

	background-repeat: no-repeat;
	background-size: cover;
}
.conteudo {
	h1 {
		text-align: center;
		font-size: 70px;
		margin: 50px 0;
	}

	.btn.btn-light {
		width: 120px;
		height: 120px;
		margin: 1px;
		background-color: transparent;
		border: none;
		font-size: 80px;
	}

	.tabuleiro {
		border-collapse: collapse;
		margin: 0 auto;

		td {
			border: 0 solid #000;
		}

		tr:nth-child(1) {
			td:nth-child(1) {
				border-right-width: 1px;
				border-bottom-width: 1px;
			}

			td:nth-child(2) {
				border-left-width: 1px;
				border-bottom-width: 1px;
				border-right-width: 1px;
			}

			td:nth-child(3) {
				border-left-width: 1px;
				border-bottom-width: 1px;
			}
		}

		tr:nth-child(2) {
			td:nth-child(1) {
				border-right-width: 1px;
				border-bottom-width: 1px;
				border-top-width: 1px;
			}
			td:nth-child(2) {
				border-right-width: 1px;
				border-bottom-width: 1px;
				border-top-width: 1px;
			}
			td:nth-child(3) {
				border-bottom-width: 1px;
			}
		}
		tr:nth-child(3) {
			td:nth-child(1) {
				border-right-width: 1px;
			}
			td:nth-child(2) {
				border-right-width: 1px;
			}
		}
	}

	.placar {
		text-align: center;
		h2 {
			font-size: 50px;
		}
		p,
		.pontos {
			font-size: 30px;
		}

		.pontos {
			margin-left: 5px;
		}
	}
}
.rodape {
	float: right;
	margin-top: 50px;
	font-size: 18px;
	font-weight: bold;
	a {
		text-decoration: none;
		color: #000;

		&:hover {
			color: rgb(70, 69, 69);
		}
	}
}
