@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{font-family:'Amatic SC', cursive;background-image:url(/static/media/envelhecido.8af50896.jpg);background-repeat:no-repeat;background-size:cover}.conteudo h1{text-align:center;font-size:70px;margin:50px 0}.conteudo .btn.btn-light{width:120px;height:120px;margin:1px;background-color:transparent;border:none;font-size:80px}.conteudo .tabuleiro{border-collapse:collapse;margin:0 auto}.conteudo .tabuleiro td{border:0 solid #000}.conteudo .tabuleiro tr:nth-child(1) td:nth-child(1){border-right-width:1px;border-bottom-width:1px}.conteudo .tabuleiro tr:nth-child(1) td:nth-child(2){border-left-width:1px;border-bottom-width:1px;border-right-width:1px}.conteudo .tabuleiro tr:nth-child(1) td:nth-child(3){border-left-width:1px;border-bottom-width:1px}.conteudo .tabuleiro tr:nth-child(2) td:nth-child(1){border-right-width:1px;border-bottom-width:1px;border-top-width:1px}.conteudo .tabuleiro tr:nth-child(2) td:nth-child(2){border-right-width:1px;border-bottom-width:1px;border-top-width:1px}.conteudo .tabuleiro tr:nth-child(2) td:nth-child(3){border-bottom-width:1px}.conteudo .tabuleiro tr:nth-child(3) td:nth-child(1){border-right-width:1px}.conteudo .tabuleiro tr:nth-child(3) td:nth-child(2){border-right-width:1px}.conteudo .placar{text-align:center}.conteudo .placar h2{font-size:50px}.conteudo .placar p,.conteudo .placar .pontos{font-size:30px}.conteudo .placar .pontos{margin-left:5px}.rodape{float:right;margin-top:50px;font-size:18px;font-weight:bold}.rodape a{text-decoration:none;color:#000}.rodape a:hover{color:#464545}

